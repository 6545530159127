import axiosAuthoringInstance, { setAxiosTimeout } from 'api/axios-client';
import store from 'store/store';
import { getMoreSubstancesCount } from 'services/user/thunks';
import { fetchLocationStructure } from 'services/location/thunk';
import { SUBSTANCE_FILTER_LOCATION_STATUS } from 'enums/substance-filter-enum';

const addSDSToLocation = (
  locationID,
  sdsIDs,
  fork = false,
  addToLibrary = false,
  addFromGlobalSearch = false
) => {
  const req = {
    sds_ids: sdsIDs,
    added_by_global_search: addFromGlobalSearch ? true : undefined,
  };

  const newParams = new URLSearchParams();
  let apiUrl = `/locations/${locationID}/addSDS/`;
  if (fork) newParams.append('fork', true);
  if (addToLibrary) newParams.append('add_to_library', true);
  return axiosAuthoringInstance
    .post(apiUrl, req, { params: newParams })
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const moveSDSToLocation = (locationID, substanceID) => {
  return axiosAuthoringInstance
    .post(`/locations/moveSDS/`, {
      substance_id: substanceID,
      destination_location_id: locationID,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const createLocation = (
  parentId,
  name,
  responsiblePerson = null,
  needCopyAccess = false
) => {
  return axiosAuthoringInstance
    .post(`/locations/`, {
      parent_department_id: parentId,
      name: name,
      responsible_user_id: responsiblePerson,
      copy_access_from_parent_location: needCopyAccess,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const grantAccessToDepartments = (departmentsAccess, userId) => {
  return axiosAuthoringInstance
    .post(`/locations/grantAccess/`, {
      departments_access: departmentsAccess,
      user_id: userId,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const reImportSDSBinder = (
  id,
  need_ocr,
  archived,
  import_in_progress,
  import_failed
) => {
  let data = new FormData();
  if (id) data.append('imported_pdf_id', id);
  data.append('need_ocr', need_ocr);
  data.append('archived', archived);
  if (import_in_progress) data.append('import_in_progress', import_in_progress);
  if (import_failed) data.append('import_failed', import_failed);

  return axiosAuthoringInstance
    .post(`v2/locations/re-import-binder/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const importSDSBinder = (departmentID, file, requestUUID) => {
  let data = new FormData();
  data.append('department_id', departmentID);
  data.append('imported_file', file);
  data.append('id', requestUUID);
  return axiosAuthoringInstance
    .post(`/locations/binder/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const importSDSBinderV2 = (
  departmentID,
  file,
  requestUUID,
  existingBinderId,
  fromPage,
  toPage,
  sdsRequestWishItemId,
  isForReplace = false,
  internalImport = false,
  privateImport = false,
  singleSDS = false,
  taggedImport = false,
  reusedPrevious = false,
  onUploadProgress,
  departments = null,
  replacingBookletId = null
) => {
  //3 hours
  setAxiosTimeout(10800000);
  let data = new FormData();
  if (departmentID) data.append('department_id', departmentID);
  data.append('id', requestUUID);
  data.append('existing_binder_id', existingBinderId);
  if (fromPage) {
    data.append('from_page', fromPage);
  }
  if (toPage) {
    data.append('to_page', toPage);
  }
  if (isForReplace) {
    data.append('replace', true);
  }
  if (internalImport) {
    data.append('internal_import', internalImport);
  }
  if (privateImport) {
    data.append('private_import', privateImport);
  }
  if (singleSDS) {
    data.append('single_sds', singleSDS);
  }
  if (taggedImport) {
    data.append('tagged_import', taggedImport);
  }
  if (reusedPrevious) {
    data.append('reused_previous', reusedPrevious);
  }
  if (sdsRequestWishItemId && sdsRequestWishItemId !== '') {
    data.append('sds_request_wish_item_id', sdsRequestWishItemId);
  }
  if (replacingBookletId) {
    data.append('replacing_booklet_id', replacingBookletId);
  }

  if (departments && departments.length > 0)
    departments.map(item => data.append('department', item));

  if (file && file.length > 0)
    file.map(item => data.append('imported_file', item));

  return axiosAuthoringInstance
    .post(`v2/locations/binder/`, data, {
      onUploadProgress: data => {
        if (onUploadProgress !== null)
          onUploadProgress(data.loaded, data.total);
      },
    })
    .then(function (response) {
      setAxiosTimeout(50000);
      return response;
    })
    .catch(function (error) {
      setAxiosTimeout(50000);
      return error.response;
    });
};

const importSDSFromUrl = (departmentID, sds_url, requestUUID) => {
  let data = new FormData();
  data.append('department_id', departmentID);
  data.append('sds_url', sds_url);
  data.append('id', requestUUID);
  return axiosAuthoringInstance
    .post(`/v2/locations/importUrl/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const markSubstanceDiscontinued = (substanceID, updatedStatus) => {
  return axiosAuthoringInstance
    .post(`v2/substances/${substanceID}/markDiscontinued/`, {
      is_discontinued: updatedStatus,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const archiveSubstance = substanceID => {
  return axiosAuthoringInstance
    .post(`/substances/${substanceID}/markArchived/`)
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const unarchivedSubstance = substanceID => {
  return axiosAuthoringInstance
    .post(`/substances/${substanceID}/markUnarchived/`)
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const unarchivedSubstances = substanceIDs => {
  const newParams = new URLSearchParams();
  substanceIDs.map(el => newParams.append('sds_id', el));

  return axiosAuthoringInstance
    .post(`/substances/markUnarchived/`, {}, { params: newParams })
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateToNewerSDS = (locationId, sds_id) => {
  return axiosAuthoringInstance
    .post(`/substances/changeToNewerSDS/`, {
      location_id: locationId ? locationId : null,
      sds_id: sds_id,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const upgradeSubstanceToNewerSDS = (
  substanceId,
  applyToAllLocations,
  copyEditedInfo,
  copyAttachmentsList,
  copyInfoMessage,
  copyNFPAInfo
) => {
  return axiosAuthoringInstance
    .post(`v2/substances/${substanceId}/changeToNewerSDS/`, {
      apply_to_all_locations: applyToAllLocations,
      copy_edited_info: copyEditedInfo,
      copy_attachments_list: copyAttachmentsList,
      copy_info_message: copyInfoMessage,
      copy_nfpa_and_hmis_value: copyNFPAInfo,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const dismissUpgradeNewerVersion = (substanceId, reason, comment) => {
  return axiosAuthoringInstance
    .post(`v2/substances/${substanceId}/dismissUpgrade/`, {
      reason: reason,
      comment: comment,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const cancelDismissUpgradeNewerVersion = substanceId => {
  return axiosAuthoringInstance
    .post(`v2/substances/${substanceId}/cancelDismissUpgrade/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const setLocationAsDefault = locationId => {
  return axiosAuthoringInstance
    .post(`/locations/${locationId}/default/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const markAsFavorite = locationId => {
  return axiosAuthoringInstance
    .post(`/locations/${locationId}/favorite/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const hideSubstance = substanceID => {
  return axiosAuthoringInstance
    .post(`/substances/${substanceID}/hide/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const reportProblem = data => {
  let formData = new FormData();
  formData.append('subject', data.subject);
  formData.append('message', data.message);
  if (data.files) {
    for (let index = 0; index < data.files.length; index++) {
      const f = data.files[index];
      formData.append('files', f);
    }
  }
  return axiosAuthoringInstance
    .post(`/inventoryProblemReports/`, formData)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const unarchiveLocation = locationId => {
  return axiosAuthoringInstance
    .post(`/locations/${locationId}/unarchive/`)
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const createSubstance = data => {
  return axiosAuthoringInstance
    .post(`/substances/`, data)
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const matchSDSToSubstances = data => {
  return axiosAuthoringInstance
    .post(`/v2/substances/linkSDS/`, data)
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const markCompletedSDS = (substance_id, send_mail_to_requester, externalSystemId) => {
  return axiosAuthoringInstance
    .post(`/v2/substances/${substance_id}/markCompletedSDS/`, 
      { 
        send_mail_to_requester: send_mail_to_requester,
        externalSystemId: externalSystemId
       }
    )
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const changeApprovalStatus = pdfId => {
  return axiosAuthoringInstance
    .post(`/substances/approve/${pdfId}/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const moveAmountSubstance = data => {
  return axiosAuthoringInstance
    .post('v2/substances/moveAmount/', data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const submitBilling = data => {
  return axiosAuthoringInstance
    .post('/subscriptions/billing/', data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const approveOnSubstance = (substanceId, data) => {
  return axiosAuthoringInstance
    .post(`v2/substances/${substanceId}/approve/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const removeApprovalonSubstance = (
  substanceId,
  allLocation = false,
  comment = ''
) => {
  return axiosAuthoringInstance
    .post(
      `v2/substances/${substanceId}/removeApprove/?all_location=${allLocation}`,
      { comment: comment }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const bulkApproveSds = (sdsIds, comment = '') => {
  return axiosAuthoringInstance
    .post(`v2/substances/bulkApproveSds/`, {
      sds_ids: sdsIds,
      comment: comment,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const bulkEditCustomFieldSds = (sdsIds, data = {}) => {
  return axiosAuthoringInstance
    .post(`v2/substances/bulkEditCustomFieldSds/`, { sds_ids: sdsIds, ...data })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateLatestExtractedData = substanceId => {
  return axiosAuthoringInstance
    .patch(`v2/substances/${substanceId}/updateLatestExtractedData/`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const addSubstanceToLocations = payload => {
  return axiosAuthoringInstance
    .post(`/locations/addSDS/`, payload)
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const matchUploadedSDS = (
  substanceId,
  sdsId,
  useSdsData,
  applyAllLocations,
  sendMailToRequester,
  externalSystemId
) => {
  setAxiosTimeout(300000);
  let data = new FormData();
  data.append('sds_id', sdsId);
  data.append('use_sds_data', useSdsData);
  data.append('apply_all_locations', applyAllLocations);
  externalSystemId && data.append('externalSystemId', externalSystemId);
  if (sendMailToRequester) {
    data.append('send_mail_to_requester', sendMailToRequester);
  }
  return axiosAuthoringInstance
    .post(`v2/substances/${substanceId}/matchSDS/`, data)
    .then(function (response) {
      setAxiosTimeout(50000);
      return response;
    })
    .catch(function (error) {
      setAxiosTimeout(50000);
      return error.response;
    });
};

const substanceBulkAction = payload => {
  return axiosAuthoringInstance
    .post(`/v2/substances/bulkAction/`, payload)
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const archivedAllSDS = params => {
  const newParams = new URLSearchParams();
  params.sds_id?.map(el => newParams.append('sds_id', el));
  return axiosAuthoringInstance
    .post(`/v2/substances/archivedAll/`, {}, { params: newParams })
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const replaceToNewSDS = (
  currentSubstanceId,
  newSdsId,
  applyToAllLocations,
  copyEditedInfo,
  copyAttachments,
  copyInfoMessage,
  fromGlobalSearch,
  copyNFPAInfo,
) => {
  return axiosAuthoringInstance
    .post(`/v2/substances/${currentSubstanceId}/replace/`, {
      new_sds_id: newSdsId,
      apply_to_all_locations: applyToAllLocations,
      copy_edited_info: copyEditedInfo,
      copy_attachments_list: copyAttachments,
      copy_info_message: copyInfoMessage,
      added_by_global_search: fromGlobalSearch,
      copy_nfpa_and_hmis_value: copyNFPAInfo
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const downloadImportedPdfFiles = (fileType, params, pdfList) => {
  const newParams = new URLSearchParams();

  if (params.location_id) {
    params.location_id.map(el => newParams.append('location_id', el));
  }
  if (params.resolution) {
    params.resolution.map(el => newParams.append('resolution', el));
  }
  if (params.status) {
    params.status.map(value => newParams.append('import_status', value));
  }
  const data = pdfList.length > 0 ? { pdf_list: pdfList } : {};
  if (fileType === 'xlsx') {
    setAxiosTimeout(90000);
    return axiosAuthoringInstance
      .post(`/importedBinders/exportPdfListToExcel/`, data, {
        params: newParams,
        responseType: 'blob',
      })
      .then(function (response) {
        setAxiosTimeout(50000);
        return response;
      })
      .catch(function (error) {
        setAxiosTimeout(50000);
        return error.response;
      });
  }
  return axiosAuthoringInstance
    .post(`/importedBinders/downloadPdfFilesToZip/`, data, {
      params: newParams,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const addCustomTag = payload => {
  return axiosAuthoringInstance
    .post(`/customer/addCustomTag/`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const addSDSAttachment = (sdsId, file, linkAllSds) => {
  let data = new FormData();
  data.append('attachment', file);
  data.append('apply_all_sds', linkAllSds);

  return axiosAuthoringInstance.post(
    `/v2/substances/${sdsId}/addAttachment/`,
    data
  );
};

const priceCalculator = payload => {
  return axiosAuthoringInstance
    .post(`/subscriptions/priceCalculation/`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const replaceBookletWithSds = (bookletId, sdsId, locationId, externalSystemId) => {
  return axiosAuthoringInstance
    .post(`/v2/locations/replaceBookletWithSds/`, {
      booklet_id: bookletId,
      sds_id: sdsId,
      department_id: locationId,
      externalSystemId: externalSystemId,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const sendEmailAccessQRUser = data => {
  return axiosAuthoringInstance
    .post(`/sendQRLoginUser/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const setIPRestrictionUserQrLogin = (
  userId,
  enableIPRestrictionStatus,
  allowedIPs
) => {
  return axiosAuthoringInstance
    .post(`/user/${userId}/userIPRestrict/`, {
      enable_ip_restrict_status: enableIPRestrictionStatus,
      allowed_ips: allowedIPs,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const exportLocationXlsx = (locationID, includeSublocation = false) => {
  const req = {
    include_sublocations: includeSublocation,
  };

  return axiosAuthoringInstance
    .post(`/v2/locations/${locationID}/export/`, req, { responseType: 'blob' })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const computeSubstanceData = substanceId => {
  return axiosAuthoringInstance
    .post(`v2/substances/${substanceId}/computeData/`, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const unlinkSDS = substanceId => {
  return axiosAuthoringInstance
    .post(`v2/substances/${substanceId}/unlinkSDS/`, {})
    .then(function (response) {
      store.dispatch(getMoreSubstancesCount());
      store.dispatch(fetchLocationStructure());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const sendEmailToGetSDS = data => {
  return axiosAuthoringInstance
    .post(`/getSDSFromDistributor/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const handleTocNavigate = (pdfBinder, endPage) => {
  setAxiosTimeout(300000);
  let data = new FormData();
  data.append('pdf_binder', pdfBinder);
  data.append('end_page', endPage);

  return axiosAuthoringInstance
    .post(`/v2/locations/handleTocNavigate/`, data, { responseType: 'blob' })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const exportHazardousXlsx = params => {
  const newParams = new URLSearchParams();
  params.chemical_regulation_list_ids?.map(el =>
    newParams.append('chemical_regulation_list_ids', el)
  );
  if (params.search) newParams.append('search', params.search);
  if (params.sds_status) newParams.append('sds_status', params.sds_status);
  if (params.approval_status)
    newParams.append('approve_status', params.approval_status);
  if (params.locations) {
    if (
      Object.values(SUBSTANCE_FILTER_LOCATION_STATUS).includes(params.locations)
    ) {
      newParams.append('location_filter', params.locations);
    } else newParams.append('location_id', params.locations);
  }
  if (params.hazardous_risk_assessment) {
    newParams.append(
      'hazardous_risk_assessment',
      params.hazardous_risk_assessment
    );
  }
  if (params.order_by != undefined)
    newParams.append('order_by', params.order_by);
  else newParams.append('order_by', 'product_name');

  return axiosAuthoringInstance
    .post(
      `/v2/substances/exportHazardous/`,
      {},
      { params: newParams, responseType: 'blob' }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const updateLatestSdsVersion = () => {
  return axiosAuthoringInstance
    .post(`v2/substances/changeToLatestVersion/`, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const addSDSRequestComment = (substanceId, comment) => {
  return axiosAuthoringInstance
    .post(`v2/substances/${substanceId}/addComment/`, {
      comment: comment,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const sendSupportRequest = data => {
  return axiosAuthoringInstance
    .post(`/inventorySupportRequest/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const forceImportBookletId = (id, data) => {
  return axiosAuthoringInstance
    .post(`/importedBinders/${id}/forceImport/`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

const exportSubstances = () => {
  return axiosAuthoringInstance
    .post(`/v2/substances/export/`, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export {
  addSDSToLocation,
  archiveSubstance,
  changeApprovalStatus,
  createLocation,
  grantAccessToDepartments,
  importSDSBinder,
  importSDSFromUrl,
  hideSubstance,
  setLocationAsDefault,
  unarchivedSubstance,
  updateToNewerSDS,
  markAsFavorite,
  matchSDSToSubstances,
  reportProblem,
  createSubstance,
  unarchiveLocation,
  moveSDSToLocation,
  moveAmountSubstance,
  submitBilling,
  approveOnSubstance,
  removeApprovalonSubstance,
  importSDSBinderV2,
  reImportSDSBinder,
  updateLatestExtractedData,
  addSubstanceToLocations,
  matchUploadedSDS,
  substanceBulkAction,
  archivedAllSDS,
  downloadImportedPdfFiles,
  addCustomTag,
  replaceToNewSDS,
  addSDSAttachment,
  priceCalculator,
  replaceBookletWithSds,
  sendEmailAccessQRUser,
  setIPRestrictionUserQrLogin,
  exportLocationXlsx,
  computeSubstanceData,
  unlinkSDS,
  sendEmailToGetSDS,
  unarchivedSubstances,
  handleTocNavigate,
  upgradeSubstanceToNewerSDS,
  exportHazardousXlsx,
  updateLatestSdsVersion,
  bulkApproveSds,
  markSubstanceDiscontinued,
  markCompletedSDS,
  bulkEditCustomFieldSds,
  dismissUpgradeNewerVersion,
  cancelDismissUpgradeNewerVersion,
  addSDSRequestComment,
  sendSupportRequest,
  forceImportBookletId,
  exportSubstances,
};
