import { createSlice } from '@reduxjs/toolkit';
import { PERMISSION } from 'enums/permissions.enums';

export interface CommonState {
  isPinned: boolean;
  expandedItem: string | null;
  locationId: string | number | null;
  pdfId: string | number | null;
  openUpgradePlanPopup: boolean;
  featureNotAvailable: PERMISSION | null;
  showTrialPlanToolTip: boolean;
  expandMenu: boolean;
  loadingModal: boolean;
  openDrawer: boolean;
  inputExternalSystemId: string | number | null;
}

const initialState: CommonState = {
  isPinned: false,
  expandedItem: null,
  locationId: null,
  pdfId: null,
  openUpgradePlanPopup: false,
  featureNotAvailable: null,
  showTrialPlanToolTip: false,
  expandMenu: false,
  loadingModal: false,
  openDrawer: false,
  inputExternalSystemId: null,
};

const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsPinned(state, action: { payload: boolean }) {
      state.isPinned = action.payload;
    },
    setExpandedItem(state, action: { payload: string | null }) {
      state.expandedItem = action.payload;
    },
    setLocationId(state, action: { payload: string | number | null }) {
      state.locationId = action.payload;
    },
    setPdfId(state, action: { payload: string | number | null }) {
      state.pdfId = action.payload;
    },
    setOpenUpgradePlanPopup(state, action: { payload: boolean }) {
      state.openUpgradePlanPopup = action.payload;
    },
    setFeatureNotAvailable(state, action: { payload: PERMISSION | null }) {
      state.featureNotAvailable = action.payload;
    },
    setShowTrialPlanToolTip(state, action: { payload: boolean }) {
      state.showTrialPlanToolTip = action.payload;
    },
    setExpandMenu(state, action: { payload: boolean }) {
      state.expandMenu = action.payload;
    },
    setLoadingModal(state, action: { payload: boolean }) {
      state.loadingModal = action.payload;
    },
    setOpenDrawer(state, action: { payload: boolean }) {
      state.openDrawer = action.payload;
    },
    setSelectExternalSystemId(state, action: { payload: any }) { 
      state.inputExternalSystemId = action.payload;
    },
  },
});

export const {
  setIsPinned,
  setExpandedItem,
  setLocationId,
  setPdfId,
  setOpenUpgradePlanPopup,
  setFeatureNotAvailable,
  setShowTrialPlanToolTip,
  setExpandMenu,
  setLoadingModal,
  setOpenDrawer,
  setSelectExternalSystemId,
} = CommonSlice.actions;
export default CommonSlice.reducer;
